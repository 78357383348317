<div class="conta_iner">
        <div class="mm">
                <!-- <div class="mleft">
                        <figure>
                                <img src="assets/img/banner.jpg">
                        </figure>
                </div> -->
                <div class="verifym">
                        <div class="headerlft">
                                <figure routerLink="/home">
                                        <img src="assets/img/logo.png">
                                </figure>
                                <!-- <a>Russian Anabolics</a> -->
                        </div>

                        <!-- If Product code is verified -->
                        <div *ngIf="isSubmit&&isVerified">
                                <figure class="wgerr">
                                        <img src="assets/img/ver.gif" />
                                </figure>
                                <div class="verified">Verified! Your product is authenticated.</div>
                        </div>

                        <!-- If Product code is not verified -->
                        <div *ngIf="isSubmit&&!isVerified">
                                <figure class="wgerr">
                                        <img src="assets/img/wg.gif" />
                                </figure>
                                <div class="error">Not verified! Please check the code again or try a different code.
                                </div>
                        </div>

                        <h4>Please Enter the Product ID</h4>
                        <input type="text" placeholder="Authenticity Code" [(ngModel)]="code">
                        <button (click)="verify()">Verify</button>
                        <!-- <h6>© 2022 by Russian Anabolics. | Develop & Design By: Webincline</h6> -->
                </div>
        </div>
</div>