<section class="headermain">
  <div class="conta_iner">
    <div class="header">
      <div class="headerryt">
        <ul>
          <li routerLink="/home" routerLinkActive="active">Home</li>
          <li routerLink="/about" routerLinkActive="active">About</li>
        </ul>
      </div>
      <div class="headerlft">
        <figure routerLink="/home">
          <img src="assets/img/logo.png">
        </figure>
      </div>
      <div class="headerRight">
        <ul>
          <li routerLink="/product" routerLinkActive="active">Products</li>
          <li (click)="openDialog()" routerLinkActive="active">Authentication</li>
        </ul>
      </div>
    </div>
  </div>
</section>


<header class="header">
  <div class="conta_iner">
    <nav class="navbar navbar-inverse">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
          <i class="fa fa-bars" aria-hidden="true"></i>
        </button>
        <a class="navbar-brand">
          <figure>
            <img src="assets/img/logo.png">
          </figure>
          <!-- <a>Russian Anabolics</a> -->
        </a>
      </div>
      <div class="collapse navbar-collapse" id="myNavbar">
        <ul class="nav navbar-nav">
          <li routerLink="/home" routerLinkActive="active">Home</li>
          <li routerLink="/about" routerLinkActive="active">About</li>
          <li routerLink="/product" routerLinkActive="active">Products</li>
          <li (click)="openDialog()" routerLinkActive="active">Authentication</li>
        </ul>

      </div>
    </nav>
  </div>
</header>