import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ProductauthenticationComponent } from 'src/app/pages/productauthentication/productauthentication.component';
declare const $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  
})
export class HeaderComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

   ngOnInit(): void {
    $(function() {
      //caches a jQuery object containing the header element
      var header = $(".clearHeader");
      $(window).scroll(function() {
        
          var scroll = $(window).scrollTop();
  
          if (scroll >= 800) {
              header.removeClass('clearHeader').addClass("darkHeader");
          } else {
              header.removeClass("darkHeader").addClass('clearHeader');
          }
      });
  });
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(ProductauthenticationComponent, {
      width: '250px',
      panelClass: 'verifyCls'
      // data: {name: this.name, animal: this.animal},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
     
    });
  }
}
