import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-productauthentication',
  templateUrl: './productauthentication.component.html',
  styleUrls: ['./productauthentication.component.scss'],

})
export class ProductauthenticationComponent implements OnInit {
  codes: string[] = [];
  code: string = "";
  isVerified: boolean = false;
  isSubmit: boolean = false;

  constructor( public dialogRef: MatDialogRef<ProductauthenticationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.codes = [
      "RA150489",
      "RA230789",
      "RA221244",
      "RA291054",
      "RA210183",
      "RA220681",
      "RA120855",
      "RA191184",
      "RA151198",
      "RA080114",
      "RA220159",
      "RA091203",
      "RA100489",
      "RA101189",
      "RA190788",
      "RA010788",
      "RA100584",
      "RA091085",
      "RA060184",
      "RA190960"
    ]
  }

  ngOnInit(): void {
  }

  verify() {
    console.log(this.code);
    this.isSubmit = true;
    this.isVerified = false;
    this.codes.filter((item:string) => {
      if(item===this.code) {
        this.isVerified = true;
      }
    })
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
